import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useRef, useState } from "react";
import { Images } from "../../../../helpers/Images";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PrimaryButton from "../../../../Components/atoms/Primary/PrimaryButton";
import Groups2Icon from "@mui/icons-material/Groups2";
import DescriptionIcon from "@mui/icons-material/Description";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import DevicesIcon from "@mui/icons-material/Devices";
import EventIcon from "@mui/icons-material/Event";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import InsightsIcon from "@mui/icons-material/Insights";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ServiceSection from "../../../../Components/molecules/Cards/ServiceSection";
import SchoolIcon from "@mui/icons-material/School";
import TodayIcon from "@mui/icons-material/Today";
import CampaignIcon from "@mui/icons-material/Campaign";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import TextArea from "../../../../Components/atoms/TextArea/TextArea";
import TextFieldInput from "../../../../Components/atoms/TextField/TextFieldInput";
import { useForm } from "@formspree/react";
import { toast } from "react-toastify";

const Services = [];

const AccreditationTemplate = () => {
  const [state, handleSubmit] = useForm("mrbzqzyp");
  const [Name, setName] = useState("");
  const [Country, setCountry] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (state.succeeded) {
      setName("");
      setEmail("");
      setCountry("");
      setMessage("");
      toast.success(
        "Thank you for joining us! We  will get back to you as soon as possible.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: { backgroundColor: "#002369" },
        }
      );
    }
  }, [state.succeeded]);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "30px 20px",
            sm: "30px 60px",
            lg: "30px 120px",
            xl: "50px 120px",
          },
          background: `linear-gradient(80deg, #1a3978dd,#007effcc,#007effcc, #1a3978dd), #000000cc`,
          // background: `url(${Images.HomeBack})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          minHeight: "380px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.15) inset",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "32px", sm: "45px" },
            fontWeight: "500",
            color: "primary.light",
            marginBottom: "20px",
          }}
        >
          Skill Accreditation: Empowering Education Worldwide
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: { xs: "15px", sm: "20px" },
            color: "primary.light",
          }}
        >
          As the exclusive representative of STEMINDS, a leading US-based
          organization, in the Middle East and North Africa, Skill Code is
          committed to elevating the professional standards of educators and
          educational institutions. By implementing internationally recognized
          standards for STEAM education in robotics, programming, and artificial
          intelligence, Skill Accreditation fosters the creation of effective
          learning environments. Through rigorous evaluation processes and
          ongoing support, the program empowers teachers and schools to deliver
          high-quality education and nurture student talent.
        </Typography>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <PrimaryButton
            classBtn={"center"}
            text={"Join Skill Code"}
            style={{
              textTransform: "capitalize",
              borderRadius: "20px",
              color: "var(--secondary)",
              backgroundColor: "white",
              border: "2px solid var(--secondary)",
              height: "40px",
              padding: "0 25px",
              width: "fit-content",
              margin: "25px",
            }}
            click={() => navigate(`/contact`)}
          />
        </div>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1300px",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: {
              xs: "100%",
              sm: "500px",
              margin: "0 0 0 20px",
              float: "right",
            },
            flexShrink: "0",
          }}
        >
          <img
            src={Images?.kids2}
            alt="stemSchools"
            style={{
              width: "100%",
              border: "2px solid var(--primary)",
              marginBottom: "10px",
            }}
          />
        </Box>
        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>
          Program Highlights:
        </h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          <ul style={{ paddingLeft: "30px" }}>
            <li>
              <span style={{ fontWeight: "400" }}>
                Comprehensive Framework:
              </span>{" "}
              A structured framework that aligns with international best
              practices.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Customized Approach:</span>{" "}
              Tailored programs to meet the specific needs of each institution.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Expert Support:</span> Access
              to a team of experienced educators and experts.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Global Recognition:</span>{" "}
              Internationally recognized certification that enhances an
              institution's reputation.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Continuous Improvement:</span>{" "}
              Ongoing support and resources for professional development.
            </li>
          </ul>
        </Typography>
        <br />
        <br />

        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>
          Program Benefits:
        </h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          <ul style={{ paddingLeft: "30px" }}>
            <li>
              <span style={{ fontWeight: "400" }}>
                Enhanced Teacher Capacity:
              </span>{" "}
              Equips teachers with the skills and knowledge to integrate STEAM
              into their teaching.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Improved Institutional Quality:
              </span>{" "}
              Helps schools meet international quality standards.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Innovative Learning Environments:
              </span>{" "}
              Fosters student-centered learning and critical thinking.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Global Recognition:</span>{" "}
              Increases the institution's visibility and reputation.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Professional Growth:</span>{" "}
              Provides opportunities for continuous professional development.
            </li>
          </ul>
        </Typography>
        <br />
        <br />

        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>
          The Accreditation Process:
        </h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          <ul style={{ paddingLeft: "30px" }}>
            <li>
              <span style={{ fontWeight: "400" }}>Initial Assessment:</span> An
              evaluation of the institution's current practices and readiness
              for accreditation.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Development of a Development Plan:
              </span>{" "}
              Creation of a customized plan to address identified gaps.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Training and Development:
              </span>{" "}
              Provision of training and professional development opportunities.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Implementation and Evaluation:
              </span>{" "}
              Implementation of new practices and ongoing monitoring of
              progress.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Accreditation:</span> Awarding
              of accreditation upon successful completion of all requirements.
            </li>
          </ul>
        </Typography>
        <br />
        <br />

        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>
          Why Choose Skill Accreditation from Skill Code?
        </h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          <ul style={{ paddingLeft: "30px" }}>
            <li>
              <span style={{ fontWeight: "400" }}>Global Expertise:</span>{" "}
              Leveraging the expertise of STEMINDS, a leading global
              organization.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Customized Solutions:</span>{" "}
              Tailored programs to meet specific needs and contexts.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Comprehensive Support:</span>{" "}
              Ongoing support and guidance throughout the accreditation process.
            </li>
          </ul>
        </Typography>
        <br />
        <br />

        <hr />
        <Box
          sx={{
            width: "100%",
            padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
            maxWidth: "1500px",
            margin: "auto",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              textAlign: "center",
              fontSize: { xs: "28px", md: "40px" },
              fontWeight: "600",
              color: "primary.main",
              marginBottom: "20px",
            }}
          >
            Invest in the future of education. Achieve Skill Accreditation
            today!
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: "primary.darker",
              fontSize: { xs: "14px", md: "16px" },
              maxWidth: "900px",
              margin: "auto",
              marginTop: "0px",
              marginBottom: "30px",
            }}
          >
            Welcome to Skill Code Consultation form! Whether you're interested
            in joining our team or seeking expert consultation services, you've
            come to the right place. Please fill out the form below with the
            required information, and we'll get back to you as soon as possible.
          </Typography>
          <form onSubmit={handleSubmit}>
            <div style={{ maxWidth: "600px", margin: "0 auto 20px" }}>
              <TextFieldInput
                Required
                name="name"
                type="text"
                placeholder={"Enter your full name.."}
                className="Input"
                label="Full Name"
                newValue={Name}
                change={(e) => setName(e.target.value)}
              />
            </div>
            <div style={{ maxWidth: "600px", margin: "0 auto 20px" }}>
              <TextFieldInput
                Required
                name="email"
                type="text"
                placeholder={"Enter your email.."}
                className="Input"
                label="Email"
                newValue={Email}
                change={(e) => setEmail(e.target.value)}
              />
            </div>
            <div style={{ maxWidth: "600px", margin: "0 auto 20px" }}>
              <TextFieldInput
                Required
                select
                label="Country"
                placeholder={"Choose your country"}
                name={"country"}
                fullWidth
                className={`Input`}
                variant="outlined"
                newValue={Country}
                change={(e) => setCountry(e.target.value)}
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="" disabled>
                  Choose Country
                </MenuItem>
                <MenuItem key={1} value={"Egypt"}>
                  Egypt
                </MenuItem>
                <MenuItem key={2} value={"Saudi Arabia"}>
                  Saudi Arabia
                </MenuItem>
                <MenuItem key={3} value={"Kuwait "}>
                  Kuwait
                </MenuItem>
              </TextFieldInput>
            </div>
            <div style={{ maxWidth: "600px", margin: "0 auto 10px" }}>
              <TextArea
                Required
                name="text"
                placeholder={"Enter your message.."}
                className="Input"
                label="Message"
                newValue={Message}
                change={(e) => setMessage(e.target.value)}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                type="submit"
                classBtn={"center"}
                text={"Submit"}
                disabled={state.submitting}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "20px",
                  color: "var(--secondary)",
                  border: "2px solid var(--secondary)",
                  padding: "5px 30px",
                  width: "fit-content",
                  marginBottom: "5px",
                }}
              />
            </div>
          </form>
        </Box>
      </Box>
      {/* <Box
        id={"questions"}
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Questions & Answers
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "120px", md: "200px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto 0 auto",
          }}
        />
        <Box id={"questions"} sx={{ marginTop: "50px" }}>
          {[
            {
              question: "How to join ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
          ].map((item) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box> */}
    </>
  );
};

export default AccreditationTemplate;
