import React, { useEffect } from "react";
import SkillDayTemplate from "../../../Templetes/Services/ServicesDetails/SkillDay/SkillDay.template";

const SkillDayPage = () => {
  useEffect(() => {
    document.title = "Skill Code - Skill Day";
  }, []);
  return <SkillDayTemplate />;
};

export default SkillDayPage;
