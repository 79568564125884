import React, { useEffect } from "react";
import ParentTemplate from "../../Templetes/Parent/Parent.template";

const ParentPage = () => {
  useEffect(() => {
    document.title = "Skill Code - Parent";
  }, []);
  return <ParentTemplate />;
};

export default ParentPage;
