import React, { useEffect } from "react";
import AccreditationTemplate from "../../../Templetes/Services/ServicesDetails/Accreditation/Accreditation.template";

const AccreditationPage = () => {
  useEffect(() => {
    document.title = "Skill Code - Accreditation";
  }, []);
  return <AccreditationTemplate />;
};

export default AccreditationPage;
