import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Images } from "../../helpers/Images";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PrimaryButton from "../../Components/atoms/Primary/PrimaryButton";
import Groups2Icon from "@mui/icons-material/Groups2";
import DescriptionIcon from "@mui/icons-material/Description";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import DevicesIcon from "@mui/icons-material/Devices";
import EventIcon from "@mui/icons-material/Event";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import InsightsIcon from "@mui/icons-material/Insights";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

const SteamTemplate = ({ id }) => {
  console.log(id)
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{ width: "100%", display: "flex", flexDirection: id == "teacher" ? "column-reverse" : "column" }}>
        <Box>
          <Box
            sx={{
              width: "100%",
              padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
              maxWidth: "1200px",
              margin: "auto",
            }}
          >
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "center",
                fontSize: { xs: "40px", sm: "40px" },
                fontWeight: "700",
                maxWidth: "1000px", margin: "auto"
              }}
            >
              Transfering Schools to become STEAM Based
            </Typography>
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "center",
                fontSize: { xs: "17px", sm: "18px" },
                fontWeight: "400",
                maxWidth: "900px", margin: "20px auto 0"
              }}
            >
              Ready to boost your school's learning experience and prepare your students for tomorrow's challenges? The integration of Science, Technology, Engineering, Arts, and Mathematics fosters creativity, critical thinking, and innovation. Learn how your school can gain from hands-on, experiential learning that is captivating and inspiring. Book a call today to explore the limitless possibilities of STEAM system and how to seamlessly implement it in your school.
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center"}
                text={"Book your free consultation now!"}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "20px",
                  color: "var(--secondary)",
                  backgroundColor: "white",
                  border: "2px solid var(--secondary)",
                  height: "40px",
                  padding: "0 25px",
                  width: "fit-content",
                  margin: "25px 0 0",
                }}
                click={() => navigate(`/contact`)}
              />
            </div>
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: {
                xs: "30px 20px",
                sm: "30px 60px",
                lg: "30px 120px",
                xl: "50px 120px",
              },
              background: `linear-gradient(75deg, #00236990,#00236990), url(${Images.SchoolSteam})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              minHeight: "450px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.15) inset",
            }}
          >
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              width: "100%",
              padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
              maxWidth: "1200px",
              margin: "auto",
            }}
          >
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "center",
                fontSize: { xs: "40px", sm: "40px" },
                fontWeight: "700",
                maxWidth: "1000px", margin: "auto"
              }}
            >
              STEAM certification For Teachers
            </Typography>
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "center",
                fontSize: { xs: "17px", sm: "18px" },
                fontWeight: "400",
                maxWidth: "900px", margin: "20px auto 0"
              }}
            >
              Enhance your teaching career by obtaining a STEAM certification from STEAM.org, exclusively through Skill Code! Discover how you can revolutionize your teaching approach and make a real impact. Be the first to seize the opportunities available in the market. Hurry, seize this chance to stand out and shape your students' futures. Book a call today to learn how you can transform your teaching approach.
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center"}
                text={"Book your free consultation now!"}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "20px",
                  color: "var(--secondary)",
                  backgroundColor: "white",
                  border: "2px solid var(--secondary)",
                  height: "40px",
                  padding: "0 25px",
                  width: "fit-content",
                  margin: "25px 0 0",
                }}
                click={() => navigate(`/contact`)}
              />
            </div>
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: {
                xs: "30px 20px",
                sm: "30px 60px",
                lg: "30px 120px",
                xl: "50px 120px",
              },
              background: `linear-gradient(75deg, #00236990,#00236990), url(${Images.SchoolSteam2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              minHeight: "450px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.15) inset",
            }}
          >
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SteamTemplate;
