import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import "./FeedbackCard.css";
import PrimaryButton from "../../../atoms/Primary/PrimaryButton";
import FormModal from "../../FormModal/FormModal";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { Images } from "../../../../helpers/Images";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: (
      <SentimentVeryDissatisfiedIcon color="error" className="reportIcon" />
    ),
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" className="reportIcon" />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" className="reportIcon" />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" className="reportIcon" />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" className="reportIcon" />,
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    margin: 10,
    padding: 10,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  text: {
    margin: 10,
    fontSize: 14,
    textAlign: "justify",
  },
});

const MyDocument = ({ StudentData, Class, comp }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.title}>{StudentData?.name} Feedback</Text>
      <Text style={styles.text}>Parent Phone Number:</Text>
      <Text style={styles.text}>National ID:</Text>
      <Text style={styles.text}>Age:</Text>

      <Text style={styles.text}>School:</Text>
      <Text style={styles.text}>School:</Text>
      <Text style={styles.text}>School:</Text>
      <Text style={styles.text}>School:</Text>
      <Text style={styles.text}>School:</Text>
    </Page>
  </Document>
);

const FeedbackCard = ({ StudentData, Class }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "space-between" },
          flexWrap: "wrap",
          marginTop: "10px",
        }}
      >
        {/* <PDFDownloadLink
          document={<MyDocument StudentData={StudentData} Class={Class} />}
          fileName="report.pdf"
        >
          {({ blob, url, loading, error }) => (
            <PrimaryButton
              classBtn={"center"}
              text={"Download"}
              style={{
                textTransform: "capitalize",
                borderRadius: "10px",
                color: "white",
                backgroundColor: "var(--primary)",
                height: "35px",
                padding: "0 20px",
                width: "fit-content",
                margin: "0 5px 5px",
              }}
            />
          )}
        </PDFDownloadLink> */}
        {Class?.reports_visible && Class?.report ? (
          <>
            <Box
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                border: "2px solid #002369",
                borderRadius: "10px",
                width: { xs: "100%", md: "48%" },
                margin: "10px",
              }}
            >
              <Typography
                variant={"p"}
                sx={{
                  fontWeight: "500",
                  fontSize: { xs: "12px", sm: "15px" },
                  color: "primary.darker",
                  marginBottom: "5px",
                  overflow: "hidden",
                  maxHeight: "120px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                Feedback Summary: <br />
                {Class?.report?.summary}
              </Typography>
              <Typography
                variant={"p"}
                sx={{
                  fontWeight: "500",
                  fontSize: { xs: "12px", sm: "15px" },
                  color: "primary.darker",
                  marginBottom: "5px",
                }}
              >
                Feedback Grade:{" "}
                {parseFloat(Class?.report?.average_grade)?.toFixed(2)}
              </Typography>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <PrimaryButton
                  classBtn={"center"}
                  text={"Show more"}
                  style={{
                    textTransform: "capitalize",
                    borderRadius: "10px",
                    color: "white",
                    backgroundColor: "var(--primary)",
                    height: "35px",
                    padding: "0 20px",
                    width: "fit-content",
                    margin: "0 5px 5px",
                  }}
                  click={() => setOpen(true)}
                />
              </div>
            </Box>
          </>
        ) : (
          <Typography
            variant={"p"}
            sx={{
              fontWeight: "500",
              fontSize: { xs: "12px", sm: "15px" },
              color: "primary.darker",
              marginBottom: "5px",
            }}
          >
            No feedbacks yet..
          </Typography>
        )}
      </Box>
      <FormModal
        Open={open}
        HandleClose={() => setOpen(false)}
        XButton
        Form={
          <>
            <div className="pdfCont">
              <Typography
                variant={"p"}
                sx={{
                  fontWeight: "600",
                  fontSize: { xs: "15px", sm: "20px" },
                  color: "primary.darker",
                  marginBottom: "10px",
                  display: "block",
                }}
              >
                Student Name:{" "}
                <span style={{ fontWeight: "400" }}>{StudentData?.name}</span>
              </Typography>
              <Typography
                variant={"p"}
                sx={{
                  fontWeight: "600",
                  fontSize: { xs: "15px", sm: "20px" },
                  color: "primary.darker",
                  marginBottom: "10px",
                  display: "block",
                }}
              >
                School:{" "}
                <span style={{ fontWeight: "400" }}>
                  {StudentData?.school_name}
                </span>
              </Typography>
              <Typography
                variant={"p"}
                sx={{
                  fontWeight: "600",
                  fontSize: { xs: "15px", sm: "20px" },
                  color: "primary.darker",
                  marginBottom: "10px",
                  display: "block",
                }}
              >
                Class:{" "}
                <span style={{ fontWeight: "400" }}>{Class?.class_name}</span>
              </Typography>
              <Typography
                variant={"p"}
                sx={{
                  fontWeight: "600",
                  fontSize: { xs: "15px", sm: "20px" },
                  color: "primary.darker",
                  marginBottom: "10px",
                  display: "block",
                }}
              >
                Course:{" "}
                <span style={{ fontWeight: "400" }}>{Class?.course_title}</span>
              </Typography>
              <Typography
                variant={"p"}
                sx={{
                  fontWeight: "600",
                  fontSize: { xs: "15px", sm: "20px" },
                  color: "primary.darker",
                  marginBottom: "10px",
                  display: "block",
                }}
              >
                Teacher:
                <span style={{ fontWeight: "400" }}>{Class?.teacher_name}</span>
              </Typography>
              <Typography
                variant={"p"}
                sx={{
                  fontWeight: "600",
                  fontSize: { xs: "15px", sm: "20px" },
                  color: "primary.darker",
                  marginBottom: "10px",
                  display: "block",
                }}
              >
                Feedback Grade:{" "}
                <span style={{ fontWeight: "400", fontSize: "20px" }}>
                  {parseFloat(Class?.report?.average_grade)?.toFixed(2)} / 5
                </span>
              </Typography>
              <Typography
                variant={"p"}
                sx={{
                  fontWeight: "600",
                  fontSize: { xs: "15px", sm: "20px" },
                  color: "primary.darker",
                  marginBottom: "15px",
                  display: "block",
                }}
              >
                Feedback Details: <br />
              </Typography>
              {/* <div
              className="attributeBox"
              style={{ marginTop: "20px", width: "100%" }}
            >
              <span style={{ width: "20%" }}>Attribute</span>
              <span className="MuiRating-root">
                <span style={{ width: "20%", paddingLeft: "18px" }}>Poor</span>
                <span style={{ width: "20%", paddingLeft: "22px" }}>Fair</span>
                <span style={{ width: "20%", paddingLeft: "14px" }}>Good</span>
                <span style={{ width: "20%", paddingLeft: "0" }}>
                  Very Good
                </span>
                <span style={{ width: "20%", paddingLeft: "0" }}>
                  Excellent
                </span>
              </span>
            </div> */}
              {Class?.report?.attributes?.map((item) => (
                <div
                  className="attributeBox"
                  style={{ width: "100%", background: "#00000006" }}
                >
                  <Typography
                    variant={"p"}
                    sx={{
                      width: "30%",
                      fontWeight: "500",
                      fontSize: { xs: "13px", sm: "18px" },
                      color: "primary.darker",
                      marginBottom: "5px",
                    }}
                  >
                    - {item?.attribute}
                  </Typography>
                  <StyledRating
                    value={item?.value}
                    readOnly
                    name="highlight-selected-only"
                    IconContainerComponent={IconContainer}
                    getLabelText={(value) => customIcons[value].label}
                    highlightSelectedOnly
                  />
                </div>
              ))}
              <Typography
                variant={"p"}
                sx={{
                  fontWeight: "600",
                  fontSize: { xs: "15px", sm: "20px" },
                  color: "primary.darker",
                  margin: "15px 0",
                  display: "block",
                }}
              >
                Feedback Summary: <br />
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: { xs: "12px", sm: "14px" },
                  }}
                >
                  {Class?.report?.summary}
                </Typography>
              </Typography>
            </div>
          </>
        }
        Title={"Feedback"}
      />
    </>
  );
};

export default FeedbackCard;
