import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import "./STREAM.css";
import { Images } from "../../../../helpers/Images";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../atoms/Primary/PrimaryButton";
import { maxWidth } from "@mui/system";
import Number from "../Numbers/Number";

const STREAM = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "primary.light",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px 50px", sm: "30px 60px 50px", lg: "40px 120px 70px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Number />
        <Typography
          variant={"p"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: { xs: "13px", sm: "15px" },
            color: "primary.main",
            maxWidth: "1000px",
            margin: "50px auto 0",
          }}
        >
          Skill Code utilizes STEAM{" "}
          <strong>
            (Science, Technology, Engineering, Arts, and Mathematics)
          </strong>{" "}
          in our solutions to provide a comprehensive learning experience. By
          integrating these disciplines, we empower learners with technical
          expertise while fostering creativity, critical thinking, and
          innovation. Join us in revolutionizing education in Egypt and Arab
          countries, where our approach prepares individuals for success in
          today's dynamic world.
        </Typography>
        <img
          alt="steam"
          src={Images.stream}
          style={{ width: "100%", maxWidth: "700px", margin: "20px auto" }}
        />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <PrimaryButton
            classBtn={"center"}
            text={"Learn more"}
            style={{
              textTransform: "capitalize",
              borderRadius: "20px",
              color: "var(--primary)",
              backgroundColor: "",
              border: "2px solid var(--primary)",
              height: "40px",
              padding: "0 40px",
              width: "fit-content",
              marginTop: "0px",
            }}
            click={() => navigate(`/about`)}
          />
        </div>
      </Box>
    </Box>
  );
};

export default STREAM;
