import React, { useEffect } from "react";
import ContactTemplate from "../../Templetes/Contact/Contact.template";
import ServicesTemplate from "../../Templetes/Services/Services.template";
import SteamTemplate from "../../Templetes/Steam/Steam.template";
import { useParams } from "react-router-dom";

const SteamPage = () => {
  const params = useParams()
  useEffect(() => {
    document.title = "Skill Code - Steam Certification";
  }, []);
  return <SteamTemplate id={params?.id ?? "school"} />;
};

export default SteamPage;
