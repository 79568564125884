import { Box, Button, Grid, Typography } from "@mui/material";
import { Images } from "../../../../helpers/Images";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { isMobile } from "react-device-detect";

var imagesCar = [
  {
    image: Images.photo1,
  },
  {
    image: Images.photo2,
  },
  {
    image: Images.photo3,
  },
  {
    image: Images.photo4,
  },
  {
    image: Images.photo5,
  },
  {
    image: Images.photo6,
  },
  {
    image: Images.photo7,
  },
  {
    image: Images.photo8,
  },
  {
    image: Images.photo9,
  },
];
const CategoriesCarousel = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "0 20px 0",
            sm: "0 60px 0",
            lg: "0 120px 0",
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "1300px",
          margin: "0 auto 10px",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "30px", sm: "38px" },
            fontWeight: "500",
            color: "primary.main",
            margin: "0 0 10px",
          }}
        >
          Our Gallery
        </Typography>
        <Box
          sx={{
            width: { xs: "100%", md: "100%" },
            margin: { xs: "0 auto 50px", md: "auto" },
          }}
        >
          <Carousel
            showStatus={false}
            showThumbs={false}
            showArrows={true}
            showIndicators={false}
            infiniteLoop={true}
            autoPlay={true}
            centerMode={isMobile ? false : true}
            centerSlidePercentage={50}
            renderArrowPrev={(onClickHandler) => (
              <Box
                onClick={onClickHandler}
                sx={{
                  width: "60px",
                  height: "60px",
                  filter:
                    "brightness(0) saturate(100%) invert(71%) sepia(46%) saturate(715%) hue-rotate(34deg) brightness(100%) contrast(87%)",
                  cursor: "pointer",
                  position: "absolute",
                  zIndex: 1,
                  left: { xs: "20px", md: "-75px" },
                  top: { xs: "unset", md: "calc(50% - 30px)" },
                  bottom: { xs: "-60px", md: "unset" },
                }}
              >
                <img alt="prev" src={Images.prevArrow} />
              </Box>
            )}
            renderArrowNext={(onClickHandler) => (
              <Box
                onClick={onClickHandler}
                sx={{
                  width: "60px",
                  height: "60px",
                  filter:
                    "brightness(0) saturate(100%) invert(71%) sepia(46%) saturate(715%) hue-rotate(34deg) brightness(100%) contrast(87%)",
                  cursor: "pointer",
                  position: "absolute",
                  zIndex: 1,
                  right: { xs: "20px", md: "-75px" },
                  top: { xs: "unset", md: "calc(50% - 30px)" },
                  bottom: { xs: "-60px", md: "unset" },
                }}
              >
                <img alt="next" src={Images.nextArrow} />
              </Box>
            )}
          >
            {imagesCar?.map((item, i) => (
              <Grid
                container
                sx={{ width: "100%", margin: "0 !important" }}
                spacing={5}
                key={i}
              >
                <Grid
                  item
                  xs={12}
                  sx={{ padding: "20px !important" }}
                  // md={6}
                >
                  <img
                    alt={i}
                    src={item.image}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      width: "100%",
                      // minHeight: "330px",
                      borderRadius: "10px",
                    }}
                  />
                  {/* <Box
                    sx={{
                      backgroundImage: `url(${item.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      width: "100%",
                      minHeight: "330px",
                      borderRadius: "10px",
                    }}
                  ></Box> */}
                </Grid>
              </Grid>
            ))}
          </Carousel>
        </Box>
      </Box>
    </Box>
  );
};

export default CategoriesCarousel;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw
