import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import PrimaryButton from "../../atoms/Primary/PrimaryButton";

const ServiceSection = ({ desc, icon, title, right, button }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: right
            ? { xs: "column", md: "row" }
            : { xs: "column", md: "row-reverse" },
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "60px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: right ? "flex-start" : "flex-end",
          }}
        >
          <Box
            sx={{
              height: { xs: "210px", md: "270px" },
              width: { xs: "210px", md: "270px" },
              backgroundColor: "secondary.main",
              margin: right
                ? { xs: "10px", md: "10px 40px 10px 0px" }
                : { xs: "10px", md: "10px 0px 10px 40px" },
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                height: { xs: "180px", md: "230px" },
                width: { xs: "180px", md: "230px" },
                backgroundColor: "primary.light",
                margin: "10px",
                borderRadius: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {icon}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              textAlign: { xs: "center", md: "start" },
              fontSize: "30px",
              fontWeight: "600",
              color: "primary.main",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              textAlign: { xs: "center", md: "start" },
              // color: "primary.darker",
              marginTop: "10px",
              fontSize: { xs: "15px", md: "18px" },
            }}
          >
            {desc}
          </Typography>
          {button ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: { xs: "center", md: "flex-start" },
              }}
            >
              <PrimaryButton
                classBtn={"center"}
                text={"Learn more"}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "20px",
                  color: "var(--primary)",
                  backgroundColor: "white",
                  border: "2px solid var(--primary)",
                  height: "40px",
                  padding: "0 25px",
                  width: "fit-content",
                  margin: "10px 0 0",
                }}
                click={() => navigate(`/services/${button}`)}
              />
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </>
  );
};

export default ServiceSection;
