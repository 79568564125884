import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import "./CarouselHome.css";
import { Images } from "../../../../helpers/Images";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../atoms/Primary/PrimaryButton";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

const CarouselHome = ({ title, imgs, clients = false }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "primary.light",
        }}
      >
        {/* <Box
          sx={{
            width: "100%",
            padding: {
              xs: "0px 20px 50px",
              sm: "0px 60px 50px",
              lg: "0px 120px 70px",
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: "1500px",
            margin: "auto",
          }}
        >
          <Grid
            container
            sx={{
              marginTop: { xs: "0px" },
              alignItems: "center",
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <Grid item xs={12} md={7}>
              <Typography
                variant={"h1"}
                sx={{
                  textAlign: { xs: "center", md: "start" },
                  fontSize: { xs: "35px", sm: "40px" },
                  fontWeight: "500",
                  color: "primary.darker",
                }}
              >
                Progress Tracking and Reports
              </Typography>
              <Typography
                variant={"p"}
                sx={{
                  textAlign: { xs: "center", md: "start" },
                  fontSize: { xs: "13px", sm: "15px" },
                  fontWeight: "500",
                  color: "primary.darker",
                  maxWidth: { sx: "none", md: "500px" },
                  display: "block",
                  margin: { xs: "15px auto 0", md: "15px 0 0" },
                }}
              >
                Implementing advanced tracking systems to monitor student
                progress, Skill Code ensures real-time monitoring of student
                advancement, enabling personalized learning journeys and
                fostering continuous improvement.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "flex-start" },
                  marginBottom: { xs: "20px", md: "0" },
                }}
              >
                <PrimaryButton
                  classBtn={"center"}
                  text={"Our Services"}
                  style={{
                    textTransform: "capitalize",
                    borderRadius: "20px",
                    color: "var(--primary)",
                    backgroundColor: "",
                    border: "2px solid var(--primary)",
                    height: "40px",
                    padding: "0 40px",
                    width: "fit-content",
                    marginTop: "25px",
                  }}
                  click={() => navigate(`/services`)}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              sx={{ display: "flex", margin: { xs: "25px 0", md: "0" } }}
            >
              <img
                src={Images.laptop}
                style={{ width: "85%", margin: "auto" }}
              />
            </Grid>
          </Grid>
        </Box> */}
        <Box
          sx={{
            width: "100%",
            background: !clients
              ? `linear-gradient(80deg, #1a3978dd,#007effcc,#007effcc, #1a3978dd), #000000cc`
              : "white",
            // background: `url(${Images.HomeBack})`,
            // backgroundSize: "cover",
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            sx={{
              width: "100%",
              padding: {
                xs: clients ? "0px 0 50px" : "50px 0",
                sm: clients ? "0px 0 50px" : "50px 0",
                lg: clients ? "10px 0 70px" : "70px 0",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              maxWidth: "1500px",
              margin: "auto",
            }}
          >
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "center",
                fontSize: { xs: "35px", sm: "45px" },
                fontWeight: "500",
                color: !clients ? "primary.light" : "primary.main",
                marginBottom: "10px",
              }}
            >
              {title}
            </Typography>
            <div className="LogoContainerCont">
              <div className="LogoContainer">
                {imgs?.map((item, i) => (
                  <div className="logo" key={i}>
                    <img src={item} className="schoolLogo" alt={`school${i}`} />
                  </div>
                ))}
              </div>
              <div className="LogoContainer">
                {imgs?.map((item, i) => (
                  <div className="logo" key={i}>
                    <img src={item} className="schoolLogo" alt={`school${i}`} />
                  </div>
                ))}
              </div>
            </div>
            {clients ? (
              <Box
                sx={{
                  width: "100%",
                  padding: { xs: "0 20px", sm: "0 60px", lg: "0 120px" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  maxWidth: "1500px",
                  margin: "auto",
                  marginTop: "20px",
                }}
              >
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "center",
                    fontSize: { xs: "35px", sm: "40px" },
                    fontWeight: "500",
                    color: "primary.main",
                  }}
                >
                  STEAM Education Solutions!
                </Typography>
                <Typography
                  variant={"p"}
                  sx={{
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: { xs: "13px", sm: "15px" },
                    color: "primary.main",
                    marginTop: "10px",
                  }}
                >
                  Our comprehensive range of programs, spanning robotics,
                  programming, and artificial intelligence, is meticulously
                  designed to nurture a generation of forward-thinking
                  individuals, adept problem solvers, and proficient leaders.
                  Join us as we embark on a transformative educational odyssey,
                  empowering you to unlock your fullest potential and thrive in
                  the ever-evolving world of technology and innovation.
                </Typography>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <PrimaryButton
                    classBtn={"center"}
                    text={"Start now"}
                    style={{
                      textTransform: "capitalize",
                      borderRadius: "20px",
                      color: "var(--primary)",
                      backgroundColor: "",
                      border: "2px solid var(--primary)",
                      height: "40px",
                      padding: "0 40px",
                      width: "fit-content",
                      marginTop: "25px",
                    }}
                    click={() => navigate(`/contact`)}
                  />
                </div>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CarouselHome;
