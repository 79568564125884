import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";

const ServiceCard = ({ desc, icon, title, button = "" }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: { xs: "95%", md: "90%" },
          backgroundColor: "primary.light",
          margin: "10px",
          borderRadius: "15px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px 10px",
          boxShadow: "0px 0px 40px 5px #00236915",
          cursor: button ? "pointer" : "auto",
          "&:hover": {
            boxShadow: "0px 0px 20px 5px #00236930",
          },
        }}
        onClick={() => {
          if (button) navigate(`/services/${button}`);
        }}
      >
        <Box
          sx={{
            height: "90px",
            width: "90px",
            backgroundColor: "secondary.main",
            margin: "0 10px 10px",
            borderRadius: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icon}
        </Box>
        <h4
          style={{
            color: "var(--primary)",
            maxWidth: "300px",
            textAlign: "center",
          }}
        >
          {title}
        </h4>
        <Typography
          variant="p"
          sx={{
            color: "var(--primary)",
            maxWidth: "300px",
            textAlign: "center",
            fontSize: { xs: "12px", md: "14px" },
            marginTop: "5px",
            display: "block",
          }}
        >
          {desc}
        </Typography>
      </Box>
    </>
  );
};

export default ServiceCard;
