import React, { useEffect } from "react";
import SkillCPDTemplate from "../../../Templetes/Services/ServicesDetails/SkillCPD/SkillCPD.template";

const SkillCPDPage = () => {
  useEffect(() => {
    document.title = "Skill Code - Skill CPD";
  }, []);
  return <SkillCPDTemplate />;
};

export default SkillCPDPage;
