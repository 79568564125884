import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useRef, useState } from "react";
import { Images } from "../../../../helpers/Images";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PrimaryButton from "../../../../Components/atoms/Primary/PrimaryButton";
import Groups2Icon from "@mui/icons-material/Groups2";
import DescriptionIcon from "@mui/icons-material/Description";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import DevicesIcon from "@mui/icons-material/Devices";
import EventIcon from "@mui/icons-material/Event";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import InsightsIcon from "@mui/icons-material/Insights";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ServiceSection from "../../../../Components/molecules/Cards/ServiceSection";
import SchoolIcon from "@mui/icons-material/School";
import TodayIcon from "@mui/icons-material/Today";
import CampaignIcon from "@mui/icons-material/Campaign";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import TextArea from "../../../../Components/atoms/TextArea/TextArea";
import TextFieldInput from "../../../../Components/atoms/TextField/TextFieldInput";
import { useForm } from "@formspree/react";
import { toast } from "react-toastify";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

const Services = [];

const SkillDayTemplate = () => {
  const [state, handleSubmit] = useForm("mrbzqzyp");
  const [Name, setName] = useState("");
  const [Country, setCountry] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (state.succeeded) {
      setName("");
      setEmail("");
      setCountry("");
      setMessage("");
      toast.success(
        "Thank you for joining us! We  will get back to you as soon as possible.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: { backgroundColor: "#002369" },
        }
      );
    }
  }, [state.succeeded]);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "30px 20px",
            sm: "30px 60px",
            lg: "30px 120px",
            xl: "50px 120px",
          },
          background: `linear-gradient(80deg, #1a3978dd,#007effcc,#007effcc, #1a3978dd), #000000cc`,
          // background: `url(${Images.HomeBack})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          minHeight: "380px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.15) inset",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "32px", sm: "45px" },
            fontWeight: "500",
            color: "primary.light",
            marginBottom: "20px",
          }}
        >
          Skill Day: Explore, Innovate, Enjoy!
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: { xs: "15px", sm: "20px" },
            color: "primary.light",
          }}
        >
          Skill Day aims to provide an interactive and engaging learning
          experience for students and their families. By applying the principles
          of STEAM (Science, Technology, Engineering, Arts, and Mathematics) in
          a practical and innovative way, our day will transform into an
          exhibition of creativity where students showcase their projects,
          compete in exciting challenges, and learn from each other.
        </Typography>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <PrimaryButton
            classBtn={"center"}
            text={"Join Skill Code"}
            style={{
              textTransform: "capitalize",
              borderRadius: "20px",
              color: "var(--secondary)",
              backgroundColor: "white",
              border: "2px solid var(--secondary)",
              height: "40px",
              padding: "0 25px",
              width: "fit-content",
              margin: "25px",
            }}
            click={() => navigate(`/contact`)}
          />
        </div>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1300px",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: {
              xs: "100%",
              sm: "500px",
              margin: "0 0 0 20px",
              float: "right",
            },
            flexShrink: "0",
          }}
        >
          <img
            src={Images?.kids2}
            alt="stemSchools"
            style={{
              width: "100%",
              border: "2px solid var(--primary)",
              marginBottom: "10px",
            }}
          />
        </Box>
        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>
          Skill Day aims to:
        </h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          <ul style={{ paddingLeft: "30px" }}>
            <li>Develop students' critical thinking and creative skills.</li>
            <li>Foster a spirit of innovation and discovery.</li>
            <li>Promote collaboration and teamwork.</li>
            <li>
              Build bridges of communication between students, parents, and
              teachers.
            </li>
            <li>Create a fun and stimulating learning environment.</li>
          </ul>
        </Typography>
        <br />
        <br />

        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>
          Key activities of the day include:
        </h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          <ul style={{ paddingLeft: "30px" }}>
            <li>
              <span style={{ fontWeight: "400" }}>Interactive workshops:</span>{" "}
              Covering a variety of fields such as programming, robotics,
              design, and science.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Exciting competitions:</span>{" "}
              Individual and group challenges that encourage creative thinking
              and problem-solving.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Innovation exhibition:</span>{" "}
              To showcase students' creative projects.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Recreational corners:</span>{" "}
              Games and fun activities suitable for all ages.
            </li>
          </ul>
        </Typography>
        <br />
        <br />

        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>
          The program also aims to:
        </h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          <ul style={{ paddingLeft: "30px" }}>
            <li>Develop students' critical thinking and creative skills.</li>
            <li>Foster a spirit of innovation and discovery.</li>
            <li>Promote collaboration and teamwork.</li>
            <li>
              Build bridges of communication between students, parents, and
              teachers.
            </li>
            <li>Create a fun and stimulating learning environment.</li>
          </ul>
        </Typography>
        <br />
        <br />

        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>
          The program includes:
        </h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          <ul style={{ paddingLeft: "30px" }}>
            <li>
              <span style={{ fontWeight: "400" }}>Preparing a daily plan:</span>{" "}
              of activities and events suitable for each age group.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Providing materials and tools:
              </span>{" "}
              used during the day's events.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Providing a team of trainers and supervisors:
              </span>{" "}
              capable of implementing the day's activities.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Providing advertising and marketing materials:
              </span>{" "}
              for the activities and covering events throughout the day.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Providing full supervision:
              </span>{" "}
              of the day's implementation plans and submitting reports on the
              outcomes of the day's activities to school management.
            </li>
          </ul>
        </Typography>
        <br />
        <br />

        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>
          Key advantages of Skill Day:
        </h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          <ul style={{ paddingLeft: "30px" }}>
            <li>
              <span style={{ fontWeight: "400" }}>Comprehensive planning:</span>{" "}
              Preparing a detailed plan for events and activities, and
              identifying required resources.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Integrated team:</span>{" "}
              Assembling a team of qualified trainers and supervisors.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Stimulating environment:
              </span>{" "}
              Providing a fun and stimulating learning environment for students.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Performance evaluation:</span>{" "}
              Evaluating students' performance in various activities and
              providing feedback.
            </li>
          </ul>
        </Typography>
        <br />
        <br />

        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>
          Benefits of the program:
        </h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          <ul style={{ paddingLeft: "30px" }}>
            <li>
              <span style={{ fontWeight: "400" }}>Increasing awareness:</span>{" "}
              of the importance of STEAM.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Developing 21st-century skills:
              </span>{" "}
              through hands-on learning experiences.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Fostering innovation:</span>{" "}
              and creativity among students.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Building a cohesive learning community:
              </span>{" "}
              that encourages collaboration and teamwork.
            </li>
          </ul>
        </Typography>
        <br />

        <Typography
          variant={"p"}
          sx={{
            fontWeight: "300",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          Overall, Skill Day offers a unique opportunity for students to explore
          their creativity, develop their skills, and have fun while learning.
        </Typography>
        <br />
        <br />

        <hr />
        <Box
          sx={{
            width: "100%",
            padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
            maxWidth: "1500px",
            margin: "auto",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              textAlign: "center",
              fontSize: { xs: "28px", md: "40px" },
              fontWeight: "600",
              color: "primary.main",
              marginBottom: "20px",
            }}
          >
            Book Your Free Consultation Now
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: "primary.darker",
              fontSize: { xs: "14px", md: "16px" },
              maxWidth: "900px",
              margin: "auto",
              marginTop: "0px",
              marginBottom: "30px",
            }}
          >
            Welcome to Skill Code Consultation form! Whether you're interested
            in joining our team or seeking expert consultation services, you've
            come to the right place. Please fill out the form below with the
            required information, and we'll get back to you as soon as possible.
          </Typography>
          <form onSubmit={handleSubmit}>
            <div style={{ maxWidth: "600px", margin: "0 auto 20px" }}>
              <TextFieldInput
                Required
                name="name"
                type="text"
                placeholder={"Enter your full name.."}
                className="Input"
                label="Full Name"
                newValue={Name}
                change={(e) => setName(e.target.value)}
              />
            </div>
            <div style={{ maxWidth: "600px", margin: "0 auto 20px" }}>
              <TextFieldInput
                Required
                name="email"
                type="text"
                placeholder={"Enter your email.."}
                className="Input"
                label="Email"
                newValue={Email}
                change={(e) => setEmail(e.target.value)}
              />
            </div>
            <div style={{ maxWidth: "600px", margin: "0 auto 20px" }}>
              <TextFieldInput
                Required
                select
                label="Country"
                placeholder={"Choose your country"}
                name={"country"}
                fullWidth
                className={`Input`}
                variant="outlined"
                newValue={Country}
                change={(e) => setCountry(e.target.value)}
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="" disabled>
                  Choose Country
                </MenuItem>
                <MenuItem key={1} value={"Egypt"}>
                  Egypt
                </MenuItem>
                <MenuItem key={2} value={"Saudi Arabia"}>
                  Saudi Arabia
                </MenuItem>
                <MenuItem key={3} value={"Kuwait "}>
                  Kuwait
                </MenuItem>
              </TextFieldInput>
            </div>
            <div style={{ maxWidth: "600px", margin: "0 auto 10px" }}>
              <TextArea
                Required
                name="text"
                placeholder={"Enter your message.."}
                className="Input"
                label="Message"
                newValue={Message}
                change={(e) => setMessage(e.target.value)}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                type="submit"
                classBtn={"center"}
                text={"Submit"}
                disabled={state.submitting}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "20px",
                  color: "var(--secondary)",
                  border: "2px solid var(--secondary)",
                  padding: "5px 30px",
                  width: "fit-content",
                  marginBottom: "5px",
                }}
              />
            </div>
          </form>
        </Box>
      </Box>
      {/* <Box
        id={"questions"}
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Questions & Answers
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "120px", md: "200px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto 0 auto",
          }}
        />
        <Box id={"questions"} sx={{ marginTop: "50px" }}>
          {[
            {
              question: "How to join ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
          ].map((item) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box> */}
    </>
  );
};

export default SkillDayTemplate;
