import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./ClassCard.css";
import { BaseURL } from "../../../../Hooks/URL";
import PrimaryButton from "../../../atoms/Primary/PrimaryButton";
import FormModal from "../../FormModal/FormModal";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import FeedbackCard from "../FeedbackCard/FeedbackCard";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: (
      <SentimentVeryDissatisfiedIcon color="error" className="reportIcon" />
    ),
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" className="reportIcon" />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" className="reportIcon" />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" className="reportIcon" />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" className="reportIcon" />,
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

const ClassCard = ({ StudentData, Class }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "500",
            fontSize: { xs: "12px", sm: "15px" },
            color: "primary.darker",
          }}
        >
          {Class?.class_name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Tabs value={value} onChange={handleChange} className="scroller">
          <Tab label="Class Details" />
          <Tab label="Sessions" />
          {Class?.is_terminated ? <Tab label="Feedbacks" /> : ""}
        </Tabs>
        <div hidden={value !== 0}>
          {value === 0 && (
            <Box sx={{ p: 3, display: "flex", flexDirection: "column" }}>
              <Typography
                variant={"p"}
                sx={{
                  fontWeight: "500",
                  fontSize: { xs: "12px", sm: "15px" },
                  color: "primary.darker",
                  marginBottom: "5px",
                }}
              >
                Program: {Class?.course_title}
              </Typography>
              <Typography
                variant={"p"}
                sx={{
                  fontWeight: "500",
                  fontSize: { xs: "12px", sm: "15px" },
                  color: "primary.darker",
                  marginBottom: "5px",
                }}
              >
                Teacher: {Class?.teacher_name}
              </Typography>
              <Typography
                variant={"p"}
                sx={{
                  fontWeight: "500",
                  fontSize: { xs: "12px", sm: "15px" },
                  color: "primary.darker",
                  marginBottom: "5px",
                }}
              >
                Start Date: {Class?.start_date?.split("T")?.at(0)}
              </Typography>
            </Box>
          )}
        </div>
        <div hidden={value !== 1}>
          {value === 1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "space-between" },
                flexWrap: "wrap",
                marginTop: "10px",
              }}
            >
              {Class?.sessions?.map((session) => (
                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    border: "2px solid #002369",
                    borderRadius: "10px",
                    width: { xs: "100%", md: "48%" },
                    margin: "10px",
                  }}
                >
                  <Typography
                    variant={"p"}
                    sx={{
                      fontWeight: "500",
                      fontSize: { xs: "12px", sm: "15px" },
                      color: "primary.darker",
                      marginBottom: "5px",
                    }}
                  >
                    Session Date: {session?.date_time?.split("T")?.at(0)}
                  </Typography>
                  <Typography
                    variant={"p"}
                    sx={{
                      fontWeight: "500",
                      fontSize: { xs: "12px", sm: "15px" },
                      color: "primary.darker",
                    }}
                  >
                    Session Content: <br />
                    {session?.content}
                  </Typography>
                  {session?.task?.description ? (
                    <>
                      <Typography
                        variant={"p"}
                        sx={{
                          fontWeight: "500",
                          fontSize: { xs: "12px", sm: "15px" },
                          color: "primary.darker",
                          marginBottom: "5px",
                          marginTop: "5px",
                        }}
                      >
                        Session Task:
                        <br /> {session?.task?.description}
                      </Typography>
                      <Typography
                        variant={"p"}
                        sx={{
                          fontWeight: "500",
                          fontSize: { xs: "12px", sm: "15px" },
                          color: "primary.darker",
                          marginBottom: "5px",
                        }}
                      >
                        Task Images:{" "}
                        {session?.task?.images?.length
                          ? ""
                          : "There are no images in this task.."}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                        }}
                      >
                        {session?.task?.images?.map((image) => (
                          <img
                            src={`${BaseURL}/images/${image?.image}`}
                            style={{ width: "49%", marginBottom: "5px" }}
                          />
                        ))}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              ))}
            </Box>
          )}
        </div>
        <div hidden={value !== 2}>
          {value === 2 && (
            <FeedbackCard StudentData={StudentData} Class={Class} />
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ClassCard;
