import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import "./ServicesHome.css";
import { Images } from "../../../../helpers/Images";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../atoms/Primary/PrimaryButton";
import Groups2Icon from "@mui/icons-material/Groups2";
import DescriptionIcon from "@mui/icons-material/Description";
import ServiceCard from "../../../molecules/Cards/ServiceCard";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import DevicesIcon from "@mui/icons-material/Devices";
import EventIcon from "@mui/icons-material/Event";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import InsightsIcon from "@mui/icons-material/Insights";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import SchoolIcon from "@mui/icons-material/School";
import TodayIcon from "@mui/icons-material/Today";
import CampaignIcon from "@mui/icons-material/Campaign";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

const Services = [
  {
    title: "Skill Accreditation",
    icon: <WorkspacePremiumIcon style={{ color: "white", fontSize: "50px" }} />,
    desc: "Provide teacher, curriculum and school accreditation through Stem.ORG and Steminds.ORG accreditation.",
    button: "skillAccreditation",
  },
  {
    title: "Skill Camp",
    icon: <CampaignIcon style={{ color: "white", fontSize: "50px" }} />,
    desc: "Skill Camp is designed to equip students with a unique learning experience, transforming them from learners into innovators.",
    button: "skillCamp",
  },
  {
    title: "Skill Challenge",
    icon: <EmojiEventsIcon style={{ color: "white", fontSize: "50px" }} />,
    desc: "Skill Challenge, powered by Skill Code, is your gateway to the realms of programming, robotics, and artificial intelligence.",
    button: "skillChallenge",
  },
  {
    title: "Skill CPD",
    icon: <SchoolIcon style={{ color: "white", fontSize: "50px" }} />,
    desc: "The CPD Skill program aims to enhance teachers' knowledge and skills, empowering them to effectively implement STEAM education",
    button: "skillCPD",
  },
  {
    title: "Skill Day",
    icon: <TodayIcon style={{ color: "white", fontSize: "50px" }} />,
    desc: "Skill Day aims to provide an interactive and engaging learning experience for students and their families.",
    button: "skillDay",
  },
  {
    title: "Skill Map",
    icon: <Groups2Icon style={{ color: "white", fontSize: "50px" }} />,
    desc: "At Skill Code, we offer comprehensive solutions to transform educational institutions into world-class learning environments.",
    button: "skillMap",
  },
];

const ServicesHome = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        // background: `linear-gradient(80deg, #1a3978dd,#007effcc,#007effcc, #1a3978dd), #000000cc`,
        // background: `url(${Images.HomeBack})`,
        // backgroundSize: "cover",
        // backgroundPosition: "center",
        // backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "50px 20px",
            sm: "50px 60px",
            lg: "70px 120px",
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "30px", sm: "38px" },
            fontWeight: "500",
            color: "primary.main",
          }}
        >
          Our Solutions
        </Typography>
        {/* <Box
          sx={{
            height: "3px",
            width: "200px",
            backgroundColor: "primary.light",
            margin: "10px auto",
          }}
        /> */}

        <Grid
          container
          style={{
            justifyContent: "center",
            marginTop: "20px",
            maxWidth: "1500px",
            margin: "5px auto",
          }}
        >
          {Services?.map((ser, i) => (
            <Grid
              key={i}
              item
              xs={6}
              sm={6}
              md={4}
              lg={3}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <ServiceCard
                icon={ser?.icon}
                desc={ser?.desc}
                title={ser?.title}
                button={ser?.button}
              />
            </Grid>
          ))}
        </Grid>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PrimaryButton
            classBtn={"center"}
            text={"See more"}
            style={{
              textTransform: "capitalize",
              borderRadius: "20px",
              color: "var(--primary)",
              backgroundColor: "",
              border: "2px solid var(--primary)",
              height: "40px",
              padding: "0 40px",
              width: "fit-content",
              marginTop: "15px",
            }}
            click={() => navigate(`/services`)}
          />
        </div>
      </Box>
    </Box>
  );
};

export default ServicesHome;
