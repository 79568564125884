import React, { useEffect } from "react";
import SkillMapTemplate from "../../../Templetes/Services/ServicesDetails/SkillMap/SkillMap.template";

const SkillMapPage = () => {
  useEffect(() => {
    document.title = "Skill Code - Skill Map";
  }, []);
  return <SkillMapTemplate />;
};

export default SkillMapPage;