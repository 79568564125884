import React, { useEffect } from "react";
import CompetitionsTemplate from "../../../Templetes/Services/ServicesDetails/Competitions/Competitions.template";

const CompetitionsPage = () => {
  useEffect(() => {
    document.title = "Skill Code - Competitions";
  }, []);
  return <CompetitionsTemplate />;
};

export default CompetitionsPage;
