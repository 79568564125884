/* -- -- -- Logo -- -- -- */

import LabeledHorLogo from "../assets/Logo/Skillcode-label-hor.webp";
import WhiteLogo from "../assets/Logo/logo-02.webp";

/* -- -- -- Images -- -- -- */

import HomeBack from "../assets/back.jpg";
import menu from "../assets/menu.png";
import stream from "../assets/FinalStreamlogo.webp";
import kids2 from "../assets/kids2.webp";
import kids3 from "../assets/kids3.webp";
import kids4 from "../assets/kids4.webp";
import kids5 from "../assets/kids5.webp";
import laptop from "../assets/laptop.png";
import student from "../assets/student_place.png";
import SchoolSteam from "../assets/schoolsteam.webp";
import SchoolSteam2 from "../assets/schoolsteam2.webp";
import schoolLogo1 from "../assets/schools/schoolLogo (1).webp";
import schoolLogo2 from "../assets/schools/schoolLogo (2).webp";
import schoolLogo3 from "../assets/schools/schoolLogo (3).webp";
import schoolLogo4 from "../assets/schools/schoolLogo (4).webp";
import schoolLogo5 from "../assets/schools/schoolLogo (5).webp";
import schoolLogo6 from "../assets/schools/schoolLogo (6).webp";
import schoolLogo7 from "../assets/schools/schoolLogo (7).webp";
import schoolLogo8 from "../assets/schools/schoolLogo (8).webp";
import schoolLogo9 from "../assets/schools/schoolLogo (9).webp";
import photo1 from "../assets/gallery/photo (1).png";
import photo2 from "../assets/gallery/photo (2).png";
import photo3 from "../assets/gallery/photo (3).png";
import photo4 from "../assets/gallery/photo (4).png";
import photo5 from "../assets/gallery/photo (5).png";
import photo6 from "../assets/gallery/photo (6).png";
import photo7 from "../assets/gallery/photo (7).png";
import photo8 from "../assets/gallery/photo (8).png";
import photo9 from "../assets/gallery/photo (9).png";
import pulsonic from "../assets/Logo/Full-White.webp";
import nextArrow from "../assets/next.png";
import prevArrow from "../assets/previous.png";

export const Images = {
  nextArrow,
  prevArrow,
  LabeledHorLogo,
  HomeBack,
  menu,
  stream,
  kids2,
  kids3,
  kids4,
  kids5,
  laptop,
  student,
  schoolLogo1,
  schoolLogo2,
  schoolLogo3,
  schoolLogo4,
  schoolLogo5,
  schoolLogo6,
  schoolLogo7,
  schoolLogo8,
  schoolLogo9,
  SchoolSteam,
  SchoolSteam2,
  WhiteLogo,
  pulsonic,
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
};
