import { Box, Drawer, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Images } from "../../../helpers/Images";
import "./NavBar.css";
import MenuIcon from "@mui/icons-material/Menu";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../atoms/Primary/PrimaryButton";
import { fontFamily } from "@mui/system";

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [lang, setLang] = useState("en");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [DrawerOpen, setDrawerOpen] = useState(false);
  const handleDrawer = (bool) => {
    setDrawerOpen(bool);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLang(lng);
    if (lng == "ar") {
      document
        .getElementsByTagName("body")[0]
        .style.setProperty("font-family", "Cairo", "important");
      document
        .getElementsByTagName("body")[0]
        .style.setProperty("direction", "rtl", "important");
    } else {
      document
        .getElementsByTagName("body")[0]
        .style.setProperty("font-family", "Sora", "important");
      document
        .getElementsByTagName("body")[0]
        .style.setProperty("direction", "ltr", "important");
    }
  };

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "80px",
        backgroundColor: "primary.light",
        position: "fixed",
        top: 0,
        zIndex: "1000",
        boxShadow: "0px 0px 20px 2px rgba(78,18,17,0.2)",
      }}
    >
      <Grid
        container
        alignItems={"center"}
        sx={{
          height: "100%",
          padding: { xs: "0 20px", sm: "0 60px", lg: "0 120px" },
          justifyContent: "space-between",
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Grid item xs={2} style={{ display: "flex", justifyContent: "start" }}>
          <img
            style={{ height: "80px", cursor: "pointer" }}
            src={Images.LabeledHorLogo}
            alt="Skill Code Logo"
            onClick={() => navigate("/")}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            justifyContent: "space-around",
            alignItems: "center",
            maxWidth: "600px",
            margin: "auto",
            display: { xs: "none !important", md: "flex !important" },
          }}
        >
          <Typography className="NavLinks" onClick={() => navigate("/")}>
            {t("Navbar.home")}
          </Typography>
          <Typography
            className="NavLinks"
            onClick={() => navigate("/services")}
          >
            {t("Navbar.services")}
          </Typography>
          {/* <Typography className="NavLinks" onClick={() => navigate("/parent")}>
            {t("Navbar.monitor")}
          </Typography> */}
          <Typography className="NavLinks" onClick={() => navigate("/contact")}>
            {t("Navbar.contact")}
          </Typography>
          <Typography className="NavLinks" onClick={() => navigate("/about")}>
            {t("Navbar.about")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: { xs: "none !important", md: "flex !important" },
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <PrimaryButton
            classBtn={"center"}
            text={"Monitor Your Son"}
            style={{
              textTransform: "capitalize",
              borderRadius: "20px",
              color: "var(--primary)",
              border: "2px solid var(--primary)",
              padding: "5px 20px",
              width: "fit-content",
              marginBottom: "5px",
            }}
            click={() => navigate(`/parent`)}
          />
          {/* <PrimaryButton
            classBtn={"center"}
            text={lang == "ar" ? "Ar" : "En"}
            style={{
              textTransform: "capitalize",
              borderRadius: "20px",
              color: "var(--primary)",
              border: "2px solid var(--primary)",
              padding: "5px 20px",
              width: "fit-content",
              marginBottom: "5px",
            }}
            click={() => {
              if (lang == "ar") {
                changeLanguage("en");
              } else {
                changeLanguage("ar");
              }
            }}
          /> */}
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: { xs: "flex !important", md: "none !important" },
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <MenuIcon className="menuIcon" onClick={() => handleDrawer(true)} />
        </Grid>
      </Grid>
      <Drawer
        open={DrawerOpen}
        onClose={() => handleDrawer(false)}
        className="drawer"
      >
        <img
          style={{
            height: "80px",
            cursor: "pointer",
            width: "fit-content",
            margin: "20px 10px",
          }}
          src={Images.LabeledHorLogo}
          alt="Skill Code Logo"
          onClick={() => {
            navigate("/");
            handleDrawer(false);
          }}
        />
        <Typography
          className="NavLinks"
          onClick={() => {
            navigate("/");
            handleDrawer(false);
          }}
        >
          Home
        </Typography>

        <Typography
          className="NavLinks"
          onClick={() => {
            navigate("/services");
            handleDrawer(false);
          }}
        >
          Services
        </Typography>
        <Typography
          className="NavLinks"
          onClick={() => {
            navigate("/contact");
            handleDrawer(false);
          }}
        >
          Contact
        </Typography>
        <Typography
          className="NavLinks"
          onClick={() => {
            navigate("/about");
            handleDrawer(false);
          }}
        >
          About Us
        </Typography>
        <PrimaryButton
          classBtn={"center"}
          text={"Monitor Your Son"}
          style={{
            textTransform: "capitalize",
            borderRadius: "20px",
            color: "var(--primary)",
            backgroundColor: "",
            border: "2px solid var(--primary)",
            padding: "5px 20px",
            width: "fit-content",
            margin: "20px auto",
          }}
          click={() => {
            navigate(`/parent`);
            handleDrawer(false);
          }}
        />
      </Drawer>
    </Box>
  );
};

export default NavBar;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw
