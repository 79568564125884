import React, { useEffect } from "react";
import ContactTemplate from "../../Templetes/Contact/Contact.template";
import ServicesTemplate from "../../Templetes/Services/Services.template";

const ServicesPage = () => {
  useEffect(() => {
    document.title = "Skill Code - Services";
  }, []);
  return <ServicesTemplate />;
};

export default ServicesPage;
